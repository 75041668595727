import { useState } from "react";
import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { makeRequest } from "../axios";

function CreateCardModal(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [word, setWord] = useState("");
    const [translation, setTranslation] = useState("");
    const [wordLang, setWordLang] = useState("");
    const [translationLang, setTranslationLang] = useState("");
    const toast = useToast();
  
    const handleWordChange = (event) => {
      setWord(event.target.value);
    };
  
    const handleTranslationChange = (event) => {
      setTranslation(event.target.value);
    };
  
    const handleWordLangChange = (event) => {
      setWordLang(event.target.value);
    };
  
    const handleTranslationLangChange = (event) => {
      setTranslationLang(event.target.value);
    };
  
    const handleCreateCard = async () => {
      const newCard = {
        word: word,
        translation: translation,
        wordLang: wordLang,
        translationLang: translationLang,
      };
      try {
        await makeRequest.post("/vocab", newCard);
        onClose()
        setWord("");
        setTranslation("");
        setWordLang("");
        setTranslationLang("");
        toast({
          title: "Index card created.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error(error);
      }
    };

  return (
    <>
      <Button colorScheme="blue" onClick={onOpen} ml={3}>
        Create Card
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontSize="2xl" fontWeight="bold" mb="4">
              Create Card
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" alignItems="flex-start" mb="4">
              {" "}
              <Text fontSize="sm" fontWeight="bold" mb="2">
                Word
              </Text>
              <Input
                value={word}
                onChange={handleWordChange}
                placeholder="Enter word"
                mb="2"
              />
              <Select
                placeholder="Select language"
                value={wordLang}
                onChange={handleWordLangChange}
                mb="2"
              >
                <option value="english">English</option>
                <option value="spanish">Spanish</option>
                <option value="french">French</option>
                <option value="german">German</option>
                <option value="latin">Latin</option>
              </Select>
            </Flex>
            <Flex direction="column" alignItems="flex-start" mb="4">
              <Text fontSize="sm" fontWeight="bold" mb="2">
                Translation
              </Text>
              <Input
                value={translation}
                onChange={handleTranslationChange}
                placeholder="Enter translation"
                mb="2"
              />
              <Select
                placeholder="Select language"
                value={translationLang}
                onChange={handleTranslationLangChange}
                mb="2"
              >
                <option value="english">English</option>
                <option value="spanish">Spanish</option>
                <option value="french">French</option>
                <option value="german">German</option>
                <option value="latin">Latin</option>
              </Select>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              colorScheme="blue"
              onClick={handleCreateCard}
            >
              Create Card
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CreateCardModal;
