import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  IconButton,
  Spacer,
  Tooltip,
  Badge,
  Select,
  useToast,
} from "@chakra-ui/react";
import { FaEdit, FaSave, FaSyncAlt, FaTrashAlt } from "react-icons/fa";
import { makeRequest } from "../axios";

function IndexCard(props) {
  const toast = useToast();
  const [word, setWord] = useState(props.word);
  const [translation, setTranslation] = useState(props.translation);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [wordLang, setWordLang] = useState(
    props.wordLang || "German"
  );
  const [translationLang, setTranslationLang] = useState(
    props.translationLang || "English"
  );

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    setIsEditing(false);
    const updated = {
        word: word,
        translation: translation,
        wordLang: wordLang,
        translationLang: translationLang
    }
    try{
        await makeRequest.put(`/vocab/update/${props.id}`, updated)
        toast({
            title: "Index card updated.",
            status: "warning",
            duration: 3000,
            isClosable: true,
        });
    } catch (error){
        console.error(error)
    }
  };

  const handleWordChange = (event) => {
    setWord(event.target.value);
  };

  const handleTranslationChange = (event) => {
    setTranslation(event.target.value);
  };

  const handleWordLangChange = (event) => {
    setWordLang(event.target.value);
  };

  const handleTranslationLangChange = (event) => {
    setTranslationLang(event.target.value);
  };

  const handleDelete = async () => {
    try {
      await makeRequest.delete(`/vocab/delete/${props.id}`);
      toast({
        title: "Index card deleted.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // Handle error here
      console.error(error);
    }
  };

  return (
    <Box
      w="300px"
      h="200px"
      bg="white"
      boxShadow="md"
      borderRadius="md"
      p="4"
      cursor="pointer"
      mr="4"
    >
      <Flex alignItems="center">
        <Text fontSize="sm" color="gray.500" mr="2">
          {props.date}
        </Text>
        <Spacer />
        <Tooltip label="Flip" aria-label="Flip">
          <IconButton
            icon={<FaSyncAlt />}
            onClick={handleFlip}
            variant="ghost"
            size="sm"
          />
        </Tooltip>
        <Tooltip label="Edit" aria-label="Edit">
          <IconButton
            icon={<FaEdit />}
            onClick={handleEdit}
            variant="ghost"
            size="sm"
            ml="2"
          />
        </Tooltip>
        <Tooltip label="Delete" aria-label="Flip">
          <IconButton
            icon={<FaTrashAlt />}
            onClick={handleDelete}
            variant="ghost"
            size="sm"
          />
        </Tooltip>
      </Flex>
      {isFlipped ? (
        <Flex direction="column" alignItems="center">
          <Text fontSize="2xl" fontWeight="bold" mb="4">
            Translation
          </Text>
          {isEditing ? (
            <Flex direction="column" alignItems="center">
              <Input
                value={translation}
                onChange={handleTranslationChange}
                placeholder="Enter translation"
                mb="4"
              />
              <Select
                value={translationLang}
                onChange={handleTranslationLangChange}
                mb="4"
              >
                <option value="english">English</option>
                <option value="spanish">Spanish</option>
                <option value="french">French</option>
                <option value="german">German</option>
                <option value="latin">Latin</option>
              </Select>
              <Button colorScheme="blue" onClick={handleSave}>
                <FaSave />
              </Button>
            </Flex>
          ) : (
            <Flex direction="column" alignItems="center">
              <Text fontSize="4xl" fontWeight="bold" mb="4">
                {translation}
              </Text>
              <Badge colorScheme="green">{translationLang}</Badge>
            </Flex>
          )}
        </Flex>
      ) : (
        <Flex direction="column" alignItems="center">
          <Text fontSize="2xl" fontWeight="bold" mb="4">
            Word
          </Text>
          {isEditing ? (
            <Flex direction="column" alignItems="center">
              <Input
                value={word}
                onChange={handleWordChange}
                placeholder="Enter word"
                mb="4"
              />
              <Select
                value={wordLang}
                onChange={handleWordLangChange}
                mb="4"
              >
                <option value="english">English</option>
                <option value="spanish">Spanish</option>
                <option value="french">French</option>
                <option value="german">German</option>
                <option value="latin">Latin</option>
              </Select>
              <Button colorScheme="blue" onClick={handleSave}>
                <FaSave />
              </Button>
            </Flex>
          ) : (
            <Flex direction="column" alignItems="center">
              <Text fontSize="4xl" fontWeight="bold" mb="4">
                {word}
              </Text>
              <Badge colorScheme="green">{wordLang}</Badge>
            </Flex>
          )}
        </Flex>
      )}
    </Box>
  );
}

export default IndexCard;
