import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, Text, Grid } from "@chakra-ui/react";
import IndexCard from "./components/IndexCard";
import CreateCardModal from "./components/CreateCardModal";
import { makeRequest } from "./axios";
import StartAbfrageModal from "./components/StartAbfrageModal";
import IndexCardAbfrage from "./components/IndexCardAbfrage";

const App = () => {
  const [cards, setCards] = useState([]);
  const [learningCards, setLearningCards] = useState([]);
  const [errorCount, setErrorCount] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLearning, setIsLearning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      makeRequest.get("/vocab/all").then((res) => {
        if (JSON.stringify(res.data) !== JSON.stringify(cards)) {
          setCards(res.data);
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [cards]);

  const startLearning = async (language, amount) => {
    try {
      const res = await makeRequest.get(`/vocab/learning/${amount}/${language}`);
      setLearningCards(res.data);
      setCurrentIndex(0);
      setErrorCount(0);
      setCorrectCount(0);
      setIsLearning(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAnswer = (isCorrect) => {
    if (isCorrect) {
      setCorrectCount(correctCount + 1);
    } else {
      setErrorCount(errorCount + 1);
    }
    handleNextCard();
  };

  const handleNextCard = () => {
    if (currentIndex < learningCards.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setIsLearning(false);
    }
  };

  return (
    <Box p={4} minH="100vh" display="flex" flexDirection="column">
      <Flex justify="space-between" align="center" mb={4}>
        <Heading as="h1" size="lg">
          Vocabulary Cards
        </Heading>
        <Box>
          <StartAbfrageModal onStart={startLearning} />
          <CreateCardModal />
        </Box>
      </Flex>
      {isLearning ? (
        <Flex flex="1" align="center" justify="center" flexDirection="column">
          {learningCards.length > 0 ? (
            <>
              <Text mt={4}>
                {currentIndex + 1}/{learningCards.length}
              </Text>
              <IndexCardAbfrage
                key={learningCards[currentIndex].id}
                word={learningCards[currentIndex].word}
                translation={learningCards[currentIndex].translation}
                wordLang={learningCards[currentIndex].wordLang}
                translationLang={learningCards[currentIndex].translationLang}
                handleAnswer={handleAnswer}
              />
              <Text color="red.500" mt={4}>
                Fehler: {errorCount}
              </Text>
              <Text color="green.500" mt={4}>
                Richtig: {correctCount}
              </Text>
            </>
          ) : (
            <Text mt={4}>
              Session finished. Correct: {correctCount}, Wrong: {errorCount}
            </Text>
          )}
        </Flex>
      ) : (

        <Grid templateColumns='repeat(5, 1fr)' gap={6}>
          {cards.map((card) => (
            <IndexCard
              key={card.id}
              id={card.id}
              word={card.word}
              translation={card.translation}
              date={card.creationDate}
              wordLang={card.wordLang}
              translationLang={card.translationLang}
            />
          ))}
        </Grid>

      )}
      {cards.length === 0 && !isLearning && (
        <Text mt={4}>No vocabulary cards found.</Text>
      )}
    </Box>
  );
};

export default App;
