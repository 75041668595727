import { useState } from "react";
import {
    Box,
    Button,
    Flex,
    Text,
    Spacer,
    Badge,
    HStack,
} from "@chakra-ui/react";

function IndexCardAbfrage(props) {
    const [isFlipped, setIsFlipped] = useState(false);
    const wordLang = props.wordLang || "German"
    const translationLang = props.translationLang || "English"

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <Box
            w="300px"
            h="220px"
            bg="white"
            boxShadow="md"
            borderRadius="md"
            p="4"
            cursor="pointer"
            mr="4"
        >
            <Flex alignItems="center">
                <Text fontSize="sm" color="gray.500" mr="2">
                    {props.date}
                </Text>
            </Flex>
            {isFlipped ? (
                <Flex direction="column" alignItems="center">
                    <Text fontSize="2xl" fontWeight="bold" mb="4">
                        Translation
                    </Text>
                    <Flex direction="column" alignItems="center">
                        <Text fontSize="4xl" fontWeight="bold" mb="4">
                            {props.translation}
                        </Text>
                        <Badge colorScheme="green">{translationLang}</Badge>
                    </Flex>
                    <HStack mt={3}>
                        <Button size={"sm"} variant={"outline"} colorScheme="red" onClick={() => props.handleAnswer(false)}>Wrong</Button>
                        <Spacer />
                        <Button size={"sm"} variant={"outline"} colorScheme="green" onClick={() => props.handleAnswer(true)}>Right</Button>
                    </HStack>
                </Flex>
            ) : (
                <Flex direction="column" alignItems="center">
                    <Text fontSize="2xl" fontWeight="bold" mb="4">
                        Word
                    </Text>
                    <Flex direction="column" alignItems="center">
                        <Text fontSize="4xl" fontWeight="bold" mb="4">
                            {props.word}
                        </Text>
                        <Badge colorScheme="green">{wordLang}</Badge>
                    </Flex>
                    <Button onClick={handleFlip} size={"sm"} variant={"outline"} mt={3}>Flip</Button>
                </Flex>
            )}
        </Box>
    );
}

export default IndexCardAbfrage;
