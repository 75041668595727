import { Box, Button, Checkbox, Flex, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useDisclosure, useRadio, useRadioGroup, useToast } from "@chakra-ui/react";
import { useState } from "react";

function RadioCard(props) {
    const { getInputProps, getRadioProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getRadioProps()

    return (
        <Box as='label'>
            <input {...input} />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                _checked={{
                    bg: 'teal.600',
                    color: 'white',
                    borderColor: 'teal.600',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                px={5}
                py={3}
            >
                {props.children}
            </Box>
        </Box>
    )
}

function StartAbfrageModal({ onStart }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [amount, setAmount] = useState(10);
    const [language, setLanguage] = useState("english");
    const toast = useToast();

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'wordAmount',
        defaultValue: 10,
        onChange: setAmount,
    });
    const group = getRootProps();
    const options = [5, 10, 20, 50];

    const handleStartLearning = async () => {
        console.log(amount)
        onStart(language, amount);
        onClose();
        toast({
            title: "Learning session started.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <>
            <Button colorScheme="blue" onClick={onOpen}>
                Learn
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Text fontSize="2xl" fontWeight="bold" mb="4">
                            Learn the vocabulary
                        </Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction="column" alignItems="flex-start" mb="4">
                            <Text fontSize="sm" fontWeight="bold" mb="2">
                                Language
                            </Text>
                            <Select value={language} onChange={(e) => setLanguage(e.target.value)}>
                                <option value="english">English</option>
                                <option value="spanish">Spanish</option>
                                <option value="french">French</option>
                                <option value="german">German</option>
                                <option value="latin">Latin</option>
                            </Select>
                            <Text fontSize="sm" fontWeight="bold" mb="2">
                                Amount
                            </Text>
                            <HStack {...group}>
                                {options.map((value) => {
                                    const radio = getRadioProps({ value });
                                    return (
                                        <RadioCard key={value} {...radio}>
                                            {value}
                                        </RadioCard>
                                    );
                                })}
                            </HStack>
                            <Text fontSize="sm" fontWeight="bold" mb="2">
                                Options
                            </Text>
                            <Checkbox>Timer</Checkbox>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={3} colorScheme="blue" onClick={handleStartLearning}>
                            Start
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default StartAbfrageModal;